import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../configs/firebaseConfig";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import "./Login.css";


export const Login = () => {

    const navigate = useNavigate();
      
    const app = initializeApp(firebaseConfig);


    getAuth(app).onAuthStateChanged(function(user) {
    if (user) {
        navigate('/dashboard');
    }
    });

    useEffect((app) => {
        const buttonEl =  document.querySelector('#zalogujGoogle')
        const provider = new GoogleAuthProvider();
        const auth = getAuth(app);

        buttonEl.addEventListener("click", function() {
        
            signInWithPopup(auth, provider);
        });
    }, [])

    return(
        <Container fluid className="loginContainer">
            <Row>
                <Col sm={3} className="loginLeftColumn">
                    <h3>Zaloguj się</h3>
                    <img class="logoCarView" src="./Logo-1.jpeg" alt="app logo" />
                    <img id="zalogujGoogle" src="./google_signin_light.png" alt="sign in" />
                </Col>
                <Col className="loginRightColumn">
                </Col>
            </Row>
        </Container>
        
    )
}