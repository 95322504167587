import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "../configs/firebaseConfig";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const navigate = useNavigate();

    getAuth(app).onAuthStateChanged(function(user) {
        if (!user) {
            navigate('/login');
        }
        });

    function logoutFunction() {
        auth.signOut().then(function() {
            navigate('/login')
          }, function(error) {
            
          });
    }
    return (
        <>
            <div>Witaj w panelu zarządzania</div>
            <button onClick={logoutFunction}>Wyloguj się</button>
        </>
    )
}